import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React from 'react';

import GetStarted from 'components/GetStarted';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
// import { Usecases } from 'styles/usecases';
import 'styles/usecases.scss';
import FAQ from '../../components/Faq';
import FeatureContainer from '../../components/FeatureContainer';
import { signUp } from '../../utils/contentProvider/function';

const visualFeedbackToolFaq = [
  {
    q: 'I have two separate environments for staging and production. Should I create one or two projects?',
    a: 'You can essentially manage both under one project itself. Add your staging links inside the project on ruttl, add comments & edits for your internal team. Once these are resolved, share the guest link with your client (they won’t be able to see URLs of your staging links) & get their comments. Action these comments within your internal team till all the changes are made (along with pixel perfection) & move along on quickly making your web project live.',
  },
  {
    q: 'What type of files can you leave visual feedback on?',
    a: (
      <>
        As of now, you can review webpages, images &amp; PDFs on ruttl. We’re
        soon introducing the support of web apps, videos &amp; illustrations as
        well. Stay tuned to our
        <a
          href="https://trello.com/b/D9T51EZX"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;public roadmap&nbsp;
        </a>
        for more information.
      </>
    ),
  },
  {
    q: 'Can I review videos inside ruttl?',
    a: 'Very soon, you’ll be able to streamline your feedback process on videos as well. We’re soon bringing out the functionality to add comments to videos & review them inside ruttl itself.',
  },
  {
    q: 'Can I integrate with any other design tools?',
    a: (
      <>
        Currently we have only integrated Slack, Trello, Zapier, asana and
        Unsplash, but we soon plan to introduce integrations with InVision &amp;
        Figma.
        <a
          href="https://ruttl.com/integrations/"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;Click here&nbsp;
        </a>
        to know more about our integrations.
      </>
    ),
  },
  {
    q: 'Can I share my feedback through video recordings?',
    a: 'Yes, your teammates or clients can record video comments & share the feedback.',
  },
  {
    q: 'I don’t want rutl branding. Is Custom Branding/White labelling available?',
    a: 'Yes, custom branding or while labelling is available on ruttl. You can upload your own logo & icon and customise how ruttl looks like to your team members, collaborators and clients.',
  },
  {
    q: 'Is custom domain (CNAME) available on ruttl?',
    a: (
      <>
        Yes, as part of our team plan, we have CNAME available on ruttl. Simply
        upgrade to our team plan, head to your Account Settings &amp; follow the
        simple steps mentioned there. Alternatively, you can also follow this
        <a
          href="https://youtu.be/YBYAUiGzarM"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;quick video&nbsp;
        </a>
        (35-sec) to know more
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const VisualFeedbackTool = ({ location }) => (
  <Layout location={location}>
    {({ toggleContactUs, toggleSignup }) => (
      <>
        <Meta
          title="Ruttl - The #1 Visual Feedback Tool for designers and developers 🔥"
          description="Want to collect visual feedback on designs? Try Ruttl- the fastest visual feedback tool to review designs by leaving comments on them!"
          url="https://ruttl.com/usecases/visual-feedback-tool/"
        />
        <main className="usecases-styled-main">
          <section className="features-hero">
            <div className="container">
              <h1>One tool for all your design requirements</h1>
              <p>
                Leave comments, video recordings, and feedback on PDFs, static
                images, and more.
              </p>
              <a href="https://web.ruttl.com" className="button">
                Get Started for Free
              </a>
            </div>
          </section>
          <div className="section features-main" id="features">
            <div className="container">
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <video
                        src="/assets/img/review-static-images.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-20px)' }}>
                      <h2 className="feature-head">Static Image Review</h2>
                      <p>
                        Upload multiple versions of your chosen images and tag
                        your teammates, assign comments, share precise
                        suggestions, and resolve or unresolve comments on the
                        go.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Review a Static Image
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div className="feature-img">
                      <img
                        src="/assets/img/show-activity.png"
                        alt="ruttl activities tab"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-20px)' }}>
                      <h2 className="feature-head">
                        Quickly view ongoing activities
                      </h2>
                      <p>
                        Working on multiple visual projects? View ongoing
                        activities in each project by navigating to the
                        Activities panel on the app.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Reviewing
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/responsive.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-13px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '360px' }}>
                        Check visual designs for responsiveness
                      </h2>
                      <p style={{ maxWidth: '380px' }}>
                        Switch between desktop and mobile view to check how the
                        visual element looks across devices and make your
                        websites both mobile and web-friendly.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Responsive Feature
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div className="feature-img">
                      <video
                        src="/assets/img/guest-commenting.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-10px)' }}>
                      <h2 className="feature-head">Guest commenting</h2>
                      <p>
                        Invite your designers, creative team, and clients to get
                        feedback on their designs by sharing a simple
                        shareability link. No privacy and sign-up hassles
                        required every time.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Guest Commenting
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/leave-video-comments.png"
                          alt="illustration of video comments"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-10px)' }}>
                      <h2 className="feature-head">
                        Make detailed video recordings
                      </h2>
                      <p>
                        Give your clients and teammates the much-needed option
                        to record video comments to share their detailed reviews
                        and suggestions.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Share Video Feedback
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div className="feature-img">
                      <video
                        src="/assets/img/review-static-images.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-20px)' }}>
                      <h2 className="feature-head">
                        Replace images on Live Websites
                      </h2>
                      <p>
                        Want to see how a different image would look on your
                        website? Use our integration with Unsplash or upload an
                        image from your computer on the live website and see how
                        it looks.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Unsplash Integration
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/manage-comments-separately.png"
                          alt="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-10px)' }}>
                      <h2 className="feature-head">
                        Manage comments on different versions
                      </h2>
                      <p>
                        Have multiple versions of visual elements? Track the
                        comments on different versions one at a time and address
                        them.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Add Website Versions
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>

          <FAQ
            headingCTA
            toggleContactUs={toggleContactUs}
            greyBg
            data={visualFeedbackToolFaq}
          />
          <div className="get-started-center">
            <GetStarted toggleSignup={toggleSignup} />
          </div>
        </main>
      </>
    )}
  </Layout>
);

VisualFeedbackTool.propTypes = {
  location: PropTypes.object,
};

export default VisualFeedbackTool;
